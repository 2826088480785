// Generated by nuxt-prepare
export const network = {
  "logo": "",
  "title": "NextPark.es",
  "domain": "nextpark.es",
  "locale": "es",
  "locales": [
    "es"
  ],
  "country": "ES",
  "currency": "EUR",
  "timezone": "Europe/Warsaw",
  "format_date": "DD/MM/YYYY",
  "format_time": "HH:mm",
  "format_datetime": "HH:mm DD/MM/YYYY",
  "format_dayhours": 24,
  "format_firstdayofweek": "M",
  "direction": "LTR",
  "datepicker_mask": "DD/MM/YYYY",
  "datepicker_fdow": "2",
  "datepicker_clock": "24",
  "email": "contact@nextpark.es",
  "phone_primary": null,
  "phone_secondary": null,
  "google_tag": "",
  "google_key": "",
  "google_captcha": "",
  "url_home": "https://nextpark.es/es/",
  "url_rule": "https://nextpark.es/es/reglas/"
}
export type Network = typeof network
